import CryptoJS from "crypto-js";
import moment from "moment";
import {
  APP_UPLOAD_FILE_MIME_TYPES,
  COLOR_DEBT,
  DEPOSIT_STATUS,
  ORDER_STATUS,
} from "shared/constants/AppConst";
import {
  GENDERS,
  ORDER_STATUS_ENUM,
  S3Type,
  UNIT_SUB_TYPES_ENUM,
} from "../../shared/constants/AppEnums";
import AppOptionLabel from "../core/AppOptionLabel";
import { SelectProductOption } from "../core/InfinitySelectProduct";
import UserInfoItem from "../core/AppViewItems/UserActionInfoItem/UserInfoItem";

export const checkPermissionGranted = ({ permissions = [], scopes = [] }) => {
  return permissions.some(
    (permission) =>
      !scopes || scopes.length === 0 || scopes.includes(permission)
  );
};

export const filterPermissionGrantedList = ({
  permissions = [],
  listItems = [],
}) => {
  return listItems.filter((item) =>
    checkPermissionGranted({ permissions, scopes: item.scopes })
  );
};

export const convertToS3Link = (type, id, s3String) => {
  if (!s3String) return null;
  return `${process.env.REACT_APP_LINK_S3}${type}${
    id ? `_${id}` : ""
  }/${s3String}`;
};

export const reloadImg = async (url) => {
  await fetch(url, { cache: "reload", mode: "no-cors" });
  document.body
    .querySelectorAll(`img[src='${url}']`)
    .forEach((img) => (img.src = url));
};

export const convertDateTime = (timeStampFormat) => {
  return timeStampFormat && moment(timeStampFormat).format("DD/MM/YYYY h:mmA");
};

export const convertDate = (timeStampFormat) => {
  return timeStampFormat && moment(timeStampFormat).format("DD/MM/YYYY");
};

export const convertGender = (number) => {
  return GENDERS.find((gender) => gender.id === number)?.key;
};

export const formatDate = (date) => {
  let dateMoment;
  try {
    dateMoment = moment(date);
  } catch (e) {
    dateMoment = moment();
  }

  return dateMoment.subtract(1, "days").format();
};

export const formatTime = (time) => {
  let timeMoment;
  try {
    timeMoment = moment(time);
  } catch (e) {
    timeMoment = moment();
  }

  return timeMoment.subtract(1, "days").format("H:MM");
};

export const testImage = (url) => {
  return new Promise((resolve, reject) => {
    const tester = document.createElement("img");
    tester.addEventListener("error", () => reject("error"));
    tester.addEventListener("load", () => resolve("load"));
    tester.src = url;
  });
};

export const getEditedSingleImageInfo = (value) => {
  return {
    isEdited: value && typeof value === "object",
    isDeleted: !value,
  };
};

export const parseJwt = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const findProductImages = ({
  productImages = [],
  isFindThumb,
  isGetRaw = false,
}) =>
  productImages
    .filter((productImage) =>
      isFindThumb ? productImage?.isThumb : !productImage?.isThumb
    )
    .map((productImage) => {
      const fullLink = convertToS3Link(
        S3Type.PRODUCT,
        productImage?.productId,
        productImage?.link
      );
      return isGetRaw
        ? {
            ...productImage,
            fullLink,
          }
        : fullLink;
    });

export const convertDollarString = (value) => {
  const valueNumber = Number(value);

  return (Number.isNaN(valueNumber) ? 0 : valueNumber).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};

export const formatProductAttribute = (productFields) => {
  if (!productFields) return [];
  const orderedProductFields = productFields.sort((a, b) => {
    if (a.itemOrder < b.itemOrder) return -1;
    else if (a.itemOrder > b.itemOrder) return 1;
    return 0;
  });
  const productAttributes = [
    ...new Set(orderedProductFields.map(({ key }) => key)),
  ];
  return (
    productAttributes.map((attribute) => ({
      attribute,
      options: orderedProductFields?.filter(({ key }) => key === attribute),
    })) ?? []
  );
};

export const formatProductAttributeByAlphabet = (fields) => {
  if (!fields) return [];

  return fields.map((f) => {
    const { options } = f;
    const orderedFieldInsides = options?.sort((a, b) => {
      if (a.name < b.name) return -1;
      else if (a.name > b.name) return 1;
      return 0;
    });
    return { ...f, options: orderedFieldInsides };
  });
};

export const getProductAvailableStock = ({
  productStocks = [],
  stock,
  selectedOptions = [],
  productAttributes = [],
}) => {
  if (productAttributes?.length === 2 && selectedOptions.length === 2)
    return productStocks.find(
      ({ firstId: stockFirstId, secondId: stockSecondId }) =>
        selectedOptions.includes(stockFirstId) &&
        selectedOptions.includes(stockSecondId)
    )?.stock;
  if (productAttributes?.length === 1 && selectedOptions[0])
    return productStocks.find(
      ({ firstId: stockFirstId }) => selectedOptions[0] === stockFirstId
    )?.stock;
  if (productAttributes?.length === 0) return stock;
  return null;
};

export const calculateOrderSubtotal = ({ orderItems = [] }) => {
  const subTotalStr = orderItems
    ?.reduce((pre, cur) => {
      return pre + cur?.price * cur?.quantity;
    }, 0)
    ?.toFixed(2);
  return Number(subTotalStr);
};

const calculateCouponProduct = ({ productId, subType, value, orderItems }) => {
  let discount = 0;
  for (let i = 0; i < orderItems?.length; i++) {
    const { price, quantity, productId: itemProductId } = orderItems[i];

    if (itemProductId?.toString() === productId?.toString()) {
      const curPrice =
        subType === UNIT_SUB_TYPES_ENUM.MONEY
          ? value
          : Number(((price * value) / 100)?.toFixed(2));
      discount = discount + Number(curPrice) * quantity;
    }
  }
  return discount;
};

export const calculateOrderDiscountValue = ({
  coupon,
  orderItems,
  subTotal,
}) => {
  let couponValue = 0;
  if (coupon) {
    const { subType, value, productId } = coupon;
    if (productId) {
      couponValue = calculateCouponProduct({
        productId,
        subType,
        value,
        orderItems,
      });
    } else {
      couponValue =
        subType === UNIT_SUB_TYPES_ENUM.MONEY
          ? value
          : Number(((subTotal * value) / 100)?.toFixed(2));
    }
  }
  return couponValue;
};

export const calculateShippingFee = ({ shippingRanges, subTotal }) => {
  const targetShippingRange = shippingRanges.find(
    ({ fromPrice, toPrice }) => subTotal >= fromPrice && subTotal < toPrice
  );
  return targetShippingRange ? targetShippingRange.shippingFee : -1;
};

export const calculateOrderTotal = ({
  subTotal,
  discountValue,
  shippingFee,
  tax = 0,
  creditCardFee = 0,
  customDiscount = 0,
}) => {
  const subTotalAndDiscount =
    subTotal - discountValue - customDiscount > 0
      ? subTotal - discountValue - customDiscount
      : 0;
  return subTotalAndDiscount + tax + shippingFee + creditCardFee;
};

export const prepareColorOrderStatus = (status) => {
  return ORDER_STATUS.find(({ value }) => status === value)?.color ?? "blue";
};

export const prepareColorDepositStatus = (deposit) => {
  return DEPOSIT_STATUS.find(({ value }) => deposit === value)?.color ?? "blue";
};

export const prepareColorDebt = (status, total, deposited) => {
  switch (status) {
    case ORDER_STATUS_ENUM.PARTIAL_PAID:
      return { color: COLOR_DEBT.RED, value: total - deposited };
    case ORDER_STATUS_ENUM.CREATED:
    case ORDER_STATUS_ENUM.PAYMENT_FAIL:
      return { color: COLOR_DEBT.RED, value: total };
    case ORDER_STATUS_ENUM.FULL_PAID:
    case ORDER_STATUS_ENUM.COMPLETED:
    case ORDER_STATUS_ENUM.CANCELED:
      return { color: COLOR_DEBT.RED, value: 0 };
  }
};

export const prepareTotalDebt = (orders) => {
  return orders?.reduce((pre, o) => {
    const value = prepareColorDebt(o?.status, o?.total, o?.deposited)?.value;
    return pre + value;
  }, 0);
};

export const prepareTotalRevenue = (orders) => {
  return orders?.reduce((pre, o) => {
    return pre + (o?.total ?? 0);
  }, 0);
};

export const convertRawCategoriesToTreeDataFormat = ({
  categories = [],
  parent = null,
}) =>
  categories
    .filter((item) => item.parent === parent)
    .map(({ name, id }) => ({
      title: name,
      value: id,
      children: convertRawCategoriesToTreeDataFormat({
        categories,
        parent: id,
      }),
    }));

export const CryptoJsAES = {
  decode: (encrypted) =>
    CryptoJS.AES.decrypt(
      encrypted?.replaceAll("-", "/"),
      process.env.REACT_APP_CRYPTO_JS_SECRET ?? ""
    ).toString(CryptoJS.enc.Utf8),
  encode: (data) =>
    CryptoJS.AES.encrypt(data, process.env.REACT_APP_CRYPTO_JS_SECRET ?? "")
      .toString()
      ?.replaceAll("/", "-"),
};

export const filterByText = ({ value, input }) =>
  (value ?? "").toLowerCase().includes(input.toLowerCase());

export const formatUserName = ({ firstName, lastName, username }) => {
  if (!firstName && !lastName) {
    return username;
  }
  return `${firstName ?? ""} ${lastName ?? ""} `;
};

export const getContentFromNamePath = ({ value, namePath }) => {
  if (!namePath) return null;
  else if (typeof namePath === "string") return value?.[namePath];
  else return namePath.reduce((prev, curr) => prev?.[curr], value);
};

export const getSelectUserCommonProps = ({ users = [] }) => {
  const options = users.map(
    ({
      id,
      firstName,
      lastName,
      username,
      code,
      s3Profile,
      phoneNumber,
      email,
    }) => {
      const name =
        firstName || lastName
          ? `${firstName ?? ""} ${lastName ?? ""}`
          : username ?? "";
      return {
        label: (
          <UserInfoItem
            id={id}
            code={code}
            s3Profile={s3Profile}
            username={name}
            isSizeSmall={true}
            isShowContactInfo={true}
            phoneNumber={phoneNumber}
            email={email}
          />
        ),
        value: id,
        name,
        code,
        phoneNumber,
        email,
      };
    }
  );
  const filterOption = (input, option) =>
    filterByText({
      value: option?.name,
      input,
    }) ||
    filterByText({ value: option?.code, input }) ||
    filterByText({
      value: option?.phoneNumber,
      input,
    }) ||
    filterByText({ value: option?.email, input });
  return {
    options,
    filterOption,
    optionLabelProp: "name",
  };
};

export const getSelectProductCommonProps = ({ products = [] }) => {
  const options = products.map(
    ({ id, name, productImages, isSpecialProduct, sku }) => {
      const [thumbnail] = findProductImages({
        productImages,
        isFindThumb: true,
      });
      return {
        label: (
          <SelectProductOption
            isSpecialProduct={isSpecialProduct}
            thumbnail={thumbnail}
            name={name}
            sku={sku}
          />
        ),
        value: id,
        name,
        sku,
      };
    }
  );
  const filterOption = (input, option) =>
    filterByText({
      value: option?.name,
      input,
    }) || filterByText({ value: option?.sku, input });
  return {
    options,
    filterOption,
    optionLabelProp: "name",
  };
};

export const getSelectAgentCommonProps = ({ agents = [] }) => {
  const options = agents.map(({ id, code, firstName = "", lastName = "" }) => {
    const name = `${firstName} ${lastName}`;
    return {
      label: (
        <AppOptionLabel
          name={name}
          tags={[{ label: "Agent code", value: code }]}
        />
      ),
      value: id,
      name,
      code,
    };
  });
  const filterOption = (input, option) =>
    filterByText({
      value: option?.name,
      input,
    }) || filterByText({ value: option?.code, input });
  return {
    options,
    filterOption,
    optionLabelProp: "name",
  };
};

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getFileInfoFromFileLink = (fileLink = "") => {
  const name = fileLink.split("_").pop();
  const fileExtension = name.split(".").pop();
  let type;
  switch (fileExtension) {
    case "doc":
      type = APP_UPLOAD_FILE_MIME_TYPES.WORD[0];
      break;
    case "pdf":
      type = APP_UPLOAD_FILE_MIME_TYPES.PDF[0];
      break;
    case "xls":
    case "xlsx":
    case "ods":
      type = APP_UPLOAD_FILE_MIME_TYPES.EXCEL[0];
      break;
    default:
      type = APP_UPLOAD_FILE_MIME_TYPES.IMAGE[0];
  }
  // console.log({ type });
  return {
    name,
    type,
  };
};

export const formatAddress = (address) => {
  if (!address) return "";
  const { streetAddress, city, state, country, zipcode } = address;

  return (
    [streetAddress, city, state, country].filter((item) => !!item).join(", ") +
    " " +
    zipcode
  );
};
