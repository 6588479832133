// @ts-nocheck
import React from "react";

const Users = React.lazy(() => import("./Users"));
const EmailNewsletters = React.lazy(() => import("./EmailNewsletters"));
const Coupons = React.lazy(() => import("./Coupons"));
const CompanyAgents = React.lazy(() => import("./CompanyAgents"));
const Agents = React.lazy(() => import("./Agents"));
const Orders = React.lazy(() => import("./Orders"));
const Quotes = React.lazy(() => import("./Quotes"));
const Expenses = React.lazy(() => import("./Expenses"));
const ExpenseTypes = React.lazy(() => import("./ExpenseTypes"));

export const crmConfig = [
  {
    path: "/crm/users",
    element: <Users />,
  },
  {
    path: "/crm/email-newsletters",
    element: <EmailNewsletters />,
  },
  {
    path: "/crm/coupons",
    element: <Coupons />,
  },
  {
    path: "/crm/company-agents",
    element: <CompanyAgents />,
  },
  {
    path: "/crm/agents",
    element: <Agents />,
  },
  {
    path: "/crm/orders",
    element: <Orders />,
  },
  {
    path: "/crm/quotes",
    element: <Quotes />,
  },
  {
    path: "/crm/expenses",
    element: <Expenses />,
  },
  {
    path: "/crm/expense-types",
    element: <ExpenseTypes />,
  },
];
