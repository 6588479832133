import IntlMessages from "../../@richbeauty/utility/IntlMessages";
import UserActionInfoItem from "../../@richbeauty/core/AppViewItems/UserActionInfoItem";
import {
  DEPOSIT_STATUS_ENUM,
  ORDER_STATUS_BUTTON_ACTIONS_ENUM,
  ORDER_STATUS_ENUM,
  PRODUCT_ATTRIBUTE_ENUM,
  SHIPPING_STATUS_ENUM,
  TIME_RANGE_FILER_ENUM,
} from "./AppEnums";
import moment from "moment";
import StarItem from "../../@richbeauty/core/AppViewItems/StarItem";

export const initialUrl = "/dashboards"; // this url will open after login
export const PageDefault = 1;
export const TakeDefault = 10;

export const TABLE_ACTION_COLUMN_KEY = "action";

export const UserActionColumns = [
  {
    title: <IntlMessages id="common.createBy" />,
    key: "createBy",
    width: 250,
    render: (_data, { createdAt, createdUser }) => {
      const { id, username, firstName, lastName, s3Profile } =
        createdUser ?? {};
      const name = `${firstName} ${lastName}`;
      return (
        <UserActionInfoItem
          id={id}
          s3Profile={s3Profile}
          time={createdAt}
          username={username || name}
        />
      );
    },
  },
  {
    title: <IntlMessages id="common.updateBy" />,
    key: "updateBy",
    width: 250,
    render: (_data, { updatedAt, updatedUser }) => {
      if (!updatedUser) return null;
      const { id, username, s3Profile, firstName, lastName } = updatedUser;
      const name = `${firstName} ${lastName}`;
      return (
        <UserActionInfoItem
          id={id}
          s3Profile={s3Profile}
          time={updatedAt}
          username={username || name}
        />
      );
    },
  },
];

export const DEPOSIT_STATUS = [
  {
    value: DEPOSIT_STATUS_ENUM.WAITING_FOR_SETTLEMENT,
    color: "processing",
  },
  {
    value: DEPOSIT_STATUS_ENUM.PAID,
    color: "success",
  },
];

export const ORDER_STATUS = [
  {
    value: ORDER_STATUS_ENUM.PAYMENT_FAIL,
    color: "orange",
    actions: [
      ORDER_STATUS_BUTTON_ACTIONS_ENUM.CANCEL,
      ORDER_STATUS_BUTTON_ACTIONS_ENUM.CREATE,
    ],
  },
  {
    value: ORDER_STATUS_ENUM.WAITING_FOR_QUOTE,
    color: "orange",
    actions: [
      ORDER_STATUS_BUTTON_ACTIONS_ENUM.CANCEL,
      ORDER_STATUS_BUTTON_ACTIONS_ENUM.CREATE,
    ],
  },
  {
    value: ORDER_STATUS_ENUM.CREATED,
    color: "processing",
    actions: [
      ORDER_STATUS_BUTTON_ACTIONS_ENUM.CANCEL,
      ORDER_STATUS_BUTTON_ACTIONS_ENUM.CREATE_DEPOSIT,
    ],
  },
  {
    value: ORDER_STATUS_ENUM.PARTIAL_PAID,
    color: "cyan",
    actions: [
      ORDER_STATUS_BUTTON_ACTIONS_ENUM.CANCEL,
      ORDER_STATUS_BUTTON_ACTIONS_ENUM.CREATE_DEPOSIT,
    ],
  },
  {
    value: ORDER_STATUS_ENUM.FULL_PAID,
    color: "magenta",
    actions: [
      ORDER_STATUS_BUTTON_ACTIONS_ENUM.CANCEL,
      ORDER_STATUS_BUTTON_ACTIONS_ENUM.COMPLETE,
    ],
  },
  {
    value: ORDER_STATUS_ENUM.CANCELED,
    color: "red",
    actions: [],
  },
  {
    value: ORDER_STATUS_ENUM.COMPLETED,
    color: "success",
    actions: [],
  },
];

export const SHIPPING_STATUS = [
  {
    value: SHIPPING_STATUS_ENUM.CREATED,
    color: "processing",
    nextOptions: [
      SHIPPING_STATUS_ENUM.PREPARED,
      SHIPPING_STATUS_ENUM.DELIVERY_FAILED,
    ],
  },
  {
    value: SHIPPING_STATUS_ENUM.DELIVERED,
    color: "cyan",
    nextOptions: [SHIPPING_STATUS_ENUM.DELIVERY_FAILED],
  },
  {
    value: SHIPPING_STATUS_ENUM.HANDED_OVER,
    color: "magenta",
    nextOptions: [
      SHIPPING_STATUS_ENUM.DELIVERED,
      SHIPPING_STATUS_ENUM.DELIVERY_FAILED,
    ],
  },
  {
    value: SHIPPING_STATUS_ENUM.PREPARED,
    color: "volcano",
    nextOptions: [
      SHIPPING_STATUS_ENUM.HANDED_OVER,
      SHIPPING_STATUS_ENUM.DELIVERY_FAILED,
    ],
  },
  {
    value: SHIPPING_STATUS_ENUM.DELIVERY_FAILED,
    color: "red",
    nextOptions: [],
  },
];

export const TIME_RANGE_FILTER = new Map([
  [
    TIME_RANGE_FILER_ENUM.THIS_WEEK,
    {
      label: "This week",
      value: {
        startDate: moment().startOf("week"),
        endDate: moment(),
      },
    },
  ],
  [
    TIME_RANGE_FILER_ENUM.LAST_WEEK,
    {
      label: "Last week",
      value: {
        startDate: moment().subtract(1, "week").startOf("week"),
        endDate: moment().subtract(1, "week").endOf("week"),
      },
    },
  ],
  [
    TIME_RANGE_FILER_ENUM.THIS_MONTH,
    {
      label: "This month",
      value: {
        startDate: moment().startOf("month"),
        endDate: moment(),
      },
    },
  ],
  [
    TIME_RANGE_FILER_ENUM.LAST_MONTH,
    {
      label: "Last month",
      value: {
        startDate: moment().subtract(1, "month").startOf("month"),
        endDate: moment().subtract(1, "month").endOf("month"),
      },
    },
  ],
  [
    TIME_RANGE_FILER_ENUM.THIS_YEAR,
    {
      label: "This year",
      value: {
        startDate: moment().startOf("year"),
        endDate: moment(),
      },
    },
  ],
  [
    TIME_RANGE_FILER_ENUM.LAST_YEAR,
    {
      label: "Last year",
      value: {
        startDate: moment().subtract(1, "year").startOf("year"),
        endDate: moment().subtract(1, "year").endOf("year"),
      },
    },
  ],
  [
    TIME_RANGE_FILER_ENUM.CUSTOM,
    {
      label: "Custom time range",
    },
  ],
]);

export const MONTHS_IN_YEAR = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const productAttributeOptions = [
  {
    value: PRODUCT_ATTRIBUTE_ENUM.SIZE,
  },
  {
    value: PRODUCT_ATTRIBUTE_ENUM.COLOR,
  },
  {
    value: PRODUCT_ATTRIBUTE_ENUM.WEIGHT,
  },
  {
    value: PRODUCT_ATTRIBUTE_ENUM.SCENTS,
  },
  {
    value: PRODUCT_ATTRIBUTE_ENUM.QUANTITY,
  },
];

export const BANNERS_POSITIONS = [
  "HOME TOP",
  "HOME MIDDLE",
  "HOME BOTTOM",
  "HOME RIGHT",
  "PRODUCT BOTTOM",
  "PRODUCT RIGHT",
  "PRODUCT DETAIL BOTTOM",
  "PRODUCT DETAIL RIGHT",
  "BLOG LEFT",
  "BLOG BOTTOM",
  "BLOG RIGHT",
  "BLOG DETAIL BOTTOM",
  "BLOG DETAIL MIDDLE RIGHT",
  "BLOG DETAIL RIGHT",
];

export const PAYMENT_TYPES = [
  "Cash",
  "Check",
  "Zelle",
  "Credit Card",
  "Others",
];

export const STAR_FILTER = [
  { value: 5, label: <StarItem value={5} /> },
  { value: 4, label: <StarItem value={4} /> },
  { value: 3, label: <StarItem value={3} /> },
  { value: 2, label: <StarItem value={2} /> },
  { value: 1, label: <StarItem value={1} /> },
];

export const QUOTE_STATUS = [
  { value: "created", color: "processing" },
  { value: "done", color: "success" },
  { value: "cancel", color: "red" },
];

export const ROLE_MEMBER_NAME = "Member";

export const PASSWORD_RULES =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,}$/;

export const USERNAME_RULE = /^[a-zA-Z0-9]{8,}$/;

export const COMMON_FORM_ITEM_RULE = {
  REQUIRED: {
    required: true,
    message: "Required",
  },
  PASSWORD: {
    pattern: PASSWORD_RULES,
    message:
      "Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special",
  },
  EMAIL: {
    type: "email",
    message: "The input is not valid E-mail!",
  },
  RE_ENTER_PASSWORD: ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue("password") === value) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error("The new password that you entered do not match!")
      );
    },
  }),
  USERNAME: {
    pattern: USERNAME_RULE,
    message:
      "Username must contain 8 or more characters, have not special and space character",
  },
};

export const CARD_INFO_ITEM = {
  NAME: "name",
  NUMBER: "number",
  EXPIRY: "expiry",
  CVC: "cvc",
};

export const SHIPPING_BILLING_KEYS = [
  "firstName",
  "lastName",
  "companyName",
  "country",
  "streetAddress",
  "apartment",
  "city",
  "state",
  "zipcode",
  "phone",
];

export const COLOR_DEBT = { RED: "red", WARNING: "warning" };

export const NONE_DATA_VALUE = -1;

export const BRAND_FILTER_OPTION = [
  { value: "all", label: "All" },
  { value: "rich", label: "Rick Beauty Supply" },
  { value: "mac", label: "Vichy Spa Products" },
  { value: "empty", label: "Empty" },
];

export const ADDRESS_BOOK_FILTER_OPTION_ENUM = {
  USER_BILLING: "userBilling",
  USER_SHIPPING: "userShipping",
};
export const ADDRESS_BOOK_FILTER_OPTION = [
  { value: "userBilling", label: "User Billing" },
  { value: "userShipping", label: "User Shipping" },
];

export const APP_UPLOAD_FILE_TYPE = {
  PDF: "PDF",
  IMAGE: "IMAGE",
  WORD: "WORD",
  EXCEL: "EXCEL",
};

// docs: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
export const APP_UPLOAD_FILE_MIME_TYPES = {
  PDF: ["application/pdf"],
  WORD: [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ],
  IMAGE: [
    "image/jpeg",
    "image/avif",
    "image/bmp",
    "image/gif",
    "image/vnd.microsoft.icon",
    "image/png",
    "image/svg+xml",
    "image/tiff",
    "image/webp",
  ],
  EXCEL: [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.oasis.opendocument.spreadsheet",
  ],
};

export const BRAND_OPTIONS = {
  RICH: "Rich",
  VICHY: "ViChy",
};

export const CREDIT_OPTIONS = {
  TOP_UP: "TOPUP",
  REFUND_BY_CASH: "REFUND_BY_CASH",
  SETTLE: "SETTLE",
};
