import {
  RiCoupon2Line,
  RiDashboardLine,
  RiOrganizationChart,
} from "react-icons/ri";
import { PERMISSIONS } from "shared/constants/AppEnums";
import {
  AiOutlineMail,
  AiOutlinePicture,
  AiOutlineSetting,
  AiOutlineTags,
  AiOutlineUserAdd,
} from "react-icons/ai";
import {
  MdOutlineTopic,
  MdPostAdd,
  MdShoppingCartCheckout,
} from "react-icons/md";
import { HiOutlineReceiptTax } from "react-icons/hi";
import { GrOrganization } from "react-icons/gr";
import { FaUserTie } from "react-icons/fa";
import { BsBoxSeam } from "react-icons/bs";
import { BiCategoryAlt, BiCommentDetail } from "react-icons/bi";
import { LiaMoneyBillWaveSolid } from "react-icons/lia";

const routesConfig = [
  {
    id: "dashboards",
    messageId: "sidebar.dashboards",
    type: "group",
    children: [
      {
        id: "dashboards",
        title: "Dashboards",
        messageId: "sidebar.dashboards",
        icon: <RiDashboardLine />,
        path: "/dashboards",
      },
    ],
  },
  {
    id: "cms",
    title: "CMS",
    messageId: "sidebar.cms",
    type: "group",
    children: [
      {
        id: "blogs",
        title: "Blogs",
        messageId: "sidebar.cms.blogs",
        icon: <MdPostAdd />,
        path: "/cms/blogs",
        scopes: [
          PERMISSIONS.CREATE_BLOG,
          PERMISSIONS.EDIT_BLOG,
          PERMISSIONS.DELETE_BLOG,
        ],
      },
      {
        id: "tags",
        title: "Tags",
        messageId: "sidebar.cms.tags",
        icon: <AiOutlineTags />,
        path: "/cms/tags",
        scopes: [
          PERMISSIONS.CREATE_TAG,
          PERMISSIONS.EDIT_TAG,
          PERMISSIONS.DELETE_TAG,
        ],
      },
      {
        id: "categories",
        title: "Categories",
        messageId: "sidebar.cms.categories",
        icon: <MdOutlineTopic />,
        path: "/cms/categories",
        scopes: [
          PERMISSIONS.CREATE_CATEGORY,
          PERMISSIONS.EDIT_CATEGORY,
          PERMISSIONS.DELETE_CATEGORY,
        ],
      },
      {
        id: "shippingRanges",
        title: "ShippingRanges",
        messageId: "sidebar.cms.shippingRanges",
        icon: <HiOutlineReceiptTax />,
        path: "/cms/shipping-ranges",
        scopes: [
          PERMISSIONS.CREATE_SHIPPING_RANGE,
          PERMISSIONS.EDIT_SHIPPING_RANGE,
          PERMISSIONS.DELETE_SHIPPING_RANGE,
        ],
      },
      {
        id: "banners",
        title: "Banners",
        messageId: "sidebar.cms.banners",
        icon: <AiOutlinePicture />,
        path: "/cms/banners",
        scopes: [
          PERMISSIONS.CREATE_BANNER,
          PERMISSIONS.EDIT_BANNER,
          PERMISSIONS.DELETE_BANNER,
        ],
      },
      {
        id: "products",
        title: "Products",
        messageId: "sidebar.cms.products",
        icon: <BsBoxSeam />,
        path: "/cms/products",
        scopes: [
          PERMISSIONS.CREATE_PRODUCT,
          PERMISSIONS.EDIT_PRODUCT,
          PERMISSIONS.DELETE_PRODUCT,
        ],
      },
      {
        id: "reviews",
        title: "Reviews",
        messageId: "sidebar.cms.reviews",
        icon: <BiCommentDetail />,
        path: "/cms/reviews",
        scopes: [
          PERMISSIONS.CREATE_REVIEW,
          PERMISSIONS.EDIT_REVIEW,
          PERMISSIONS.DELETE_REVIEW,
        ],
      },
    ],
  },
  {
    id: "crm",
    title: "CRM",
    messageId: "sidebar.crm",
    type: "group",
    children: [
      {
        id: "users",
        title: "Users",
        messageId: "sidebar.crm.users",
        icon: <AiOutlineUserAdd />,
        path: "/crm/users",
        scopes: [
          PERMISSIONS.CREATE_USER,
          PERMISSIONS.EDIT_USER,
          PERMISSIONS.DELETE_USER,
        ],
      },
      {
        id: "emailNewsletters",
        title: "Email Newsletters",
        messageId: "sidebar.crm.emailNewsletters",
        icon: <AiOutlineMail />,
        path: "/crm/email-newsletters",
        scopes: [PERMISSIONS.VIEW_EMAIL_NEWSLETTER],
      },
      {
        id: "coupons",
        title: "Coupons",
        messageId: "sidebar.crm.coupons",
        icon: <RiCoupon2Line />,
        path: "/crm/coupons",
        scopes: [
          PERMISSIONS.CREATE_COUPON,
          PERMISSIONS.EDIT_COUPON,
          PERMISSIONS.DELETE_COUPON,
        ],
      },
      {
        id: "companyAgents",
        title: "Coupons",
        messageId: "sidebar.crm.companyAgents",
        icon: <GrOrganization />,
        path: "/crm/company-agents",
        scopes: [
          PERMISSIONS.CREATE_AGENT_COMPANY,
          PERMISSIONS.EDIT_AGENT_COMPANY,
          PERMISSIONS.DELETE_AGENT_COMPANY,
        ],
      },
      {
        id: "agents",
        title: "Agents",
        messageId: "sidebar.crm.agents",
        icon: <FaUserTie />,
        path: "/crm/agents",
        scopes: [
          PERMISSIONS.CREATE_AGENT,
          PERMISSIONS.EDIT_AGENT,
          PERMISSIONS.DELETE_AGENT,
        ],
      },
      {
        id: "orders",
        title: "Orders",
        messageId: "sidebar.crm.orders",
        icon: <MdShoppingCartCheckout />,
        path: "/crm/orders",
        scopes: [PERMISSIONS.CREATE_ORDER, PERMISSIONS.EDIT_ORDER],
      },
      {
        id: "expenseTypes",
        title: "Expense Types",
        messageId: "sidebar.crm.expenseTypes",
        icon: <BiCategoryAlt />,
        path: "/crm/expense-types",
        scopes: [
          PERMISSIONS.CREATE_EXPENSE_TYPE,
          PERMISSIONS.EDIT_EXPENSE_TYPE,
          PERMISSIONS.DELETE_EXPENSE_TYPE,
        ],
      },
      {
        id: "expenses",
        title: "Expenses",
        messageId: "sidebar.crm.expenses",
        icon: <LiaMoneyBillWaveSolid />,
        path: "/crm/expenses",
        scopes: [
          PERMISSIONS.CREATE_EXPENSE,
          PERMISSIONS.EDIT_EXPENSE,
          PERMISSIONS.DELETE_EXPENSE,
        ],
      },
      // {
      //   id: "quotes",
      //   title: "Quotes",
      //   messageId: "sidebar.crm.quotes",
      //   icon: <MdRequestQuote />,
      //   path: "/crm/quotes",
      //   scopes: [PERMISSIONS.EDIT_QUOTE, PERMISSIONS.DELETE_QUOTE],
      // },
    ],
  },
  {
    id: "system",
    messageId: "sidebar.systems",
    type: "group",
    children: [
      {
        id: "roles",
        title: "Roles",
        messageId: "sidebar.systems.roles",
        icon: <RiOrganizationChart />,
        path: "/systems/roles",
        scopes: [
          PERMISSIONS.CREATE_ROLE,
          PERMISSIONS.EDIT_ROLE,
          PERMISSIONS.DELETE_ROLE,
        ],
      },
      {
        id: "settings",
        title: "Settings",
        messageId: "sidebar.systems.settings",
        icon: <AiOutlineSetting />,
        path: "/systems/settings",
        scopes: [
          PERMISSIONS.CREATE_ROLE,
          PERMISSIONS.EDIT_ROLE,
          PERMISSIONS.DELETE_ROLE,
        ],
      },
    ],
  },
];
export default routesConfig;
