export const authRole = {
  admin: ["user", "admin"],
  user: ["user"],
};

export const RoutePermittedRole = {
  admin: "admin",
  user: "user",
};

export const ThemeStyle = {
  MODERN: "modern",
  STANDARD: "standard",
};

export const ThemeMode = {
  LIGHT: "light",
  SEMI_DARK: "semi-dark",
  DARK: "dark",
};
export const LayoutType = {
  FULL_WIDTH: "full-width",
  BOXED: "boxed",
  FRAMED: "framed",
};

export const MenuStyle = {
  DEFAULT: "default",
  STANDARD: "standard",
  ROUNDED: "rounded",
  ROUNDED_REVERSE: "rounded-reverse",
  CURVED_MENU: "curved-menu",
};

export const NavStyle = {
  DEFAULT: "default",
  MINI: "mini",
  MINI_SIDEBAR_TOGGLE: "mini-sidebar-toggle",
  STANDARD: "standard",
  HEADER_USER: "user-header",
  HEADER_USER_MINI: "user-mini-header",
  DRAWER: "drawer",
  BIT_BUCKET: "bit-bucket",
  H_DEFAULT: "h-default",
  HOR_HEADER_FIXED: "hor-header-fixed",
  HOR_DARK_LAYOUT: "hor-dark-layout",
};
export const FooterType = {
  FIXED: "fixed",
  FLUID: "fluid",
};
export const ThemeDirection = {
  RTL: "rtl",
  LTR: "ltr",
};

export const Fonts = {
  LIGHT: "300",
  REGULAR: "400",
  MEDIUM: "500",
  BOLD: "600",
  EXTRA_BOLD: "600",
};

export const S3Type = {
  BLOG: "blog",
  UID_AVATAR: "uid_avt",
  BANNER: "banners",
  PRODUCT: "product",
  ORDER: "order",
  WSID_FAVICON: "wsid_favicon",
  WSID_LOGO: "wsid_logo",
  WSID_FAVICON_MAC: "wsid_favicon_mac",
  WSID_LOGO_MAC: "wsid_logo_mac",
  REVIEW: "review",
  PRODUCT_STOCK: "product_stock",
};

export const BannerMediaType = {
  IMAGE: "image",
  IMAGE_DETAIL: "imageDetail",
  VIDEO: "video",
};

export const AppInputType = {
  TEXT: "text",
  TEXT_AREA: "text_area",
  NUMBER: "number",
  PASSWORD: "password",
  SELECT: "select",
  UPLOAD_SINGLE_IMAGE: "uploadSingleImage",
  UPLOAD_MULTI_IMAGE: "uploadMultiImage",
  EDITOR: "editor",
  CHECKBOX: "checkbox",
  MULTISELECT: "multiselect",
  MULTISELECT_WITH_DATA: "multiselectWithData",
  SELECT_WITH_DATA: "selectWithData",
  DATE_PICKER: "datePicker",
  TIME_PICKER: "timePicker",
  RATING: "rating",
  RADIO: "radio",
  AUTO_COMPLETE: "autoComplete",
  BUTTON: "button",
  TREE_SELECT: "treeSelect",
  TREE_SELECT_DISPLAY_LIST: "treeSelectDisplayList",
  SELECT_DISPLAY_LIST: "selectDisplayList",
  PHONE_NUMBER_INPUT: "phoneNumberInput",
  FILE_UPLOAD: "fileUpload",
};

export const SortType = {
  ASC: "ASC",
  DESC: "DESC",
};

export const PERMISSIONS = {
  CREATE_ROLE: "create-role",
  EDIT_ROLE: "edit-role",
  DELETE_ROLE: "delete-role",
  CREATE_PRODUCT: "create-product",
  EDIT_PRODUCT: "edit-product",
  DELETE_PRODUCT: "delete-product",
  CREATE_REVIEW: "create-review",
  EDIT_REVIEW: "edit-review",
  DELETE_REVIEW: "delete-review",
  CREATE_CATEGORY: "create-category",
  EDIT_CATEGORY: "edit-category",
  DELETE_CATEGORY: "delete-category",
  CREATE_TAG: "create-tag",
  EDIT_TAG: "edit-tag",
  DELETE_TAG: "delete-tag",
  CREATE_SHIPPING_RANGE: "create-shipping-range",
  EDIT_SHIPPING_RANGE: "edit-shipping-range",
  DELETE_SHIPPING_RANGE: "delete-shipping-range",
  CREATE_BANNER: "create-banner",
  EDIT_BANNER: "edit-banner",
  DELETE_BANNER: "delete-banner",
  CREATE_BLOG: "create-blog",
  EDIT_BLOG: "edit-blog",
  DELETE_BLOG: "delete-blog",
  CREATE_AGENT_COMPANY: "create-agent-company",
  EDIT_AGENT_COMPANY: "edit-agent-company",
  DELETE_AGENT_COMPANY: "delete-agent-company",
  CREATE_AGENT: "create-agent",
  EDIT_AGENT: "edit-agent",
  DELETE_AGENT: "delete-agent",
  CREATE_USER: "create-user",
  EDIT_USER: "edit-user",
  DELETE_USER: "delete-user",
  UPDATE_PASSWORD: "update-password",
  VIEW_EMAIL_NEWSLETTER: "view-email-newsletter",
  CREATE_COUPON: "create-coupon",
  EDIT_COUPON: "edit-coupon",
  DELETE_COUPON: "delete-coupon",
  CREATE_ORDER: "create-order",
  EDIT_ORDER: "edit-order",
  DELETE_ORDER: "delete-order",
  EDIT_QUOTE: "edit-quote",
  DELETE_QUOTE: "delete-quote",
  CREATE_EXPENSE: "create-expense",
  EDIT_EXPENSE: "edit-expense",
  DELETE_EXPENSE: "delete-expense",
  CREATE_EXPENSE_TYPE: "create-expense-type",
  EDIT_EXPENSE_TYPE: "edit-expense-type",
  DELETE_EXPENSE_TYPE: "delete-expense-type",
  MANAGE_DELIVERY: "manage-delivery",
  CHANGE_CREDIT: "change-credit",
};

export const GenderEnum = {
  MALE: "Nam",
  FEMALE: "Nữ",
  OTHER: "Khác",
};

export const GENDERS = [
  {
    id: 0,
    name: GenderEnum.MALE,
    key: "MALE",
  },
  {
    id: 1,
    name: GenderEnum.FEMALE,
    key: "FEMALE",
  },
  {
    id: 2,
    name: GenderEnum.OTHER,
    key: "OTHER",
  },
];

export const UNIT_TYPES = ["PERCENT", "MONEY"];
export const COUPON_TYPES_ENUM = {
  QUANTITY: "QUANTITY",
  CREDIT: "CREDIT",
};

export const COUPON_TYPES = [
  COUPON_TYPES_ENUM.CREDIT,
  COUPON_TYPES_ENUM.QUANTITY,
];

export const UNIT_SUB_TYPES_ENUM = {
  PERCENT: "PERCENT",
  MONEY: "MONEY",
};

export const UNIT_TYPES_ENUM = {
  QUANTITY: "QUANTITY",
  CREDIT: "CREDIT",
};

export const ORDER_STATUS_ENUM = {
  WAITING_FOR_QUOTE: "waiting-for-quote",
  PAYMENT_FAIL: "payment-failed",
  CREATED: "created",
  PARTIAL_PAID: "partial-paid",
  FULL_PAID: "full-paid",
  COMPLETED: "completed",
  CANCELED: "canceled",
};

export const DEPOSIT_STATUS_ENUM = {
  WAITING_FOR_SETTLEMENT: "waiting-for-settlement",
  PAID: "paid",
};

export const ORDER_STATUS_BUTTON_ACTIONS_ENUM = {
  CREATE: "create",
  CREATE_DEPOSIT: "createDeposit",
  COMPLETE: "complete",
  CANCEL: "cancel",
};

export const SHIPPING_STATUS_ENUM = {
  CREATED: "created",
  PREPARED: "prepared",
  HANDED_OVER: "handed_over",
  DELIVERED: "delivered",
  DELIVERY_FAILED: "delivery_failed",
};

export const SHIPPING_STATUS = [
  "created",
  "prepared",
  "handed_over",
  "delivered",
  "delivery_failed",
];

export const AppSearchColumn = {
  DATETIME: "DATE_TIME",
  INPUT_RANGE: "INPUT_RANGE",
  LIST_ITEM: "LIST_ITEM",
  INPUT_NUMBER: "INPUT_NUMBER",
};

export const STATISTIC_TYPES = {
  BASIC: "basic",
  ORDER_REVENUE_BY_YEAR: "order-revenue-by-year",
  RECENT_ORDER: "recent-order",
  AGENTS_SUMMARY: "agent-summary",
  COMPANY_AGENTS_SUMMARY: "company-agent-summary",
  CUSTOMER_SUMMARY: "customer-summary",
  PRODUCT_SUMMARY: "product-summary",
  DEBT_SUMMARY: "debt-summary",
  DEBT_ANONYMOUS_SUMMARY: "debt-anonymous-summary",
  PAID_DEPOSIT: "paid-deposit",
};

export const TIME_RANGE_FILER_ENUM = {
  THIS_WEEK: "THIS_WEEK",
  LAST_WEEK: "LAST_WEEK",
  THIS_MONTH: "THIS_MONTH",
  LAST_MONTH: "LAST_MONTH",
  THIS_YEAR: "THIS_YEAR",
  LAST_YEAR: "LAST_YEAR",
  CUSTOM: "CUSTOM",
};

export const PRODUCT_ATTRIBUTE_ENUM = {
  SIZE: "Sizes",
  WEIGHT: "Weights",
  COLOR: "Colors",
  SCENTS: "Scents",
  QUANTITY: "Quantities",
};

export const SEND_INVOICE_TYPE = [
  {
    name: "Draft For Review",
    value: "sale",
  },
  {
    name: "Accounting",
    value: "accounting",
  },
  {
    name: "Customer",
    value: "customer",
  },
  {
    name: "Factory Production",
    value: "factory",
  },
];

export const PAYMENT_METHOD_TYPE = [
  {
    name: "Authorize.Net",
    value: "Authorize.Net",
  },
  {
    name: "Cheque",
    value: "Cheque",
  },
  {
    name: "Zelle",
    value: "Zelle",
  },
  {
    name: "External Terminal",
    value: "External Terminal",
  },
  {
    name: "Other",
    value: "Other",
  },
];

export const RECEIPT_PAID_TYPE = [
  {
    name: "Deposited",
    value: "paid-deposited",
  },
  {
    name: "Full Payment",
    value: "paid-full-payment",
  },
];

export const TEMPLATE_INVOICE_TYPE = [
  {
    name: "Waiting for Quotation",
    value: "waiting",
  },
  {
    name: "Invoice",
    value: "invoice",
  },
  {
    name: "Receipt",
    value: "receipt",
  },
  {
    name: "Inform Tracking Number",
    value: "tracking",
  },
  {
    name: "Introduce Agent",
    value: "introduce",
  },
];

export const RICH_BEAUTY_BRANCH = {
  RICH_BEAUTY_SPA_BRAND: "Rich Beauty Supply",
  CHEM_SUPPLY_BRAND: "Vichy Spa Products",
};

export const APP_FORM_MODAL_TYPE = {
  DEFAULT: "DEFAULT",
  TAB: "TAB",
};

export const VERIFY_COUPON_STATUS_CODE = {
  VALID: 200,
  INVALID: 400,
};

export const CARD_FORM_ITEM_STATUS = {
  CREATE: "CREATE",
  PREVIEW: "PREVIEW",
  VIEW: "VIEW",
  CHANGE_CARD: "CHANGE_CARD",
};

export const AppAnimates = {
  SLIDEUPIN: {
    hidden: {
      y: "100vh",
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeIn",
        delay: 0,
        when: "beforeChildren",
        duration: 0.2,
      },
    },
  },
  SLIDEUPOUT: {
    hidden: {
      y: 0,
    },
    visible: {
      opacity: 0,
      y: "100vh",
      transition: {
        ease: "easeOut",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
      },
    },
  },
  SLIDEDOWNIN: {
    hidden: {
      opacity: 0,
      y: "-100vh",
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeIn",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
      },
    },
  },
  SLIDEDOWNOUT: {
    hidden: {
      y: 0,
    },
    visible: {
      opacity: 0,
      y: "-100vh",
      transition: {
        ease: "easeOut",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
      },
    },
  },
  SLIDELEFTIN: {
    hidden: {
      x: "100vw",
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        ease: "easeIn",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
      },
    },
  },
  SLIDELEFTOUT: {
    hidden: {
      x: 0,
    },
    visible: {
      opacity: 0,
      x: "100vw",
      transition: {
        ease: "easeOut",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
      },
    },
  },
  SLIDERIGHTIN: {
    hidden: {
      x: "-100vw",
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        ease: "easeIn",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
      },
    },
  },
  SLIDERIGHTOUT: {
    hidden: {
      x: 0,
    },
    visible: {
      opacity: 0,
      x: "-100vw",
      transition: {
        ease: "easeOut",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
      },
    },
  },
  FADEIN: {
    hidden: {
      opacity: 0,
      scale: 1,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        ease: "easeIn",
        when: "beforeChildren",
        staggerChildren: 0.15,
      },
    },
  },
  FADEOUT: {
    hidden: {
      opacity: 1,
      scale: 1,
    },
    visible: {
      opacity: 0,
      scale: 1,
      transition: {
        ease: "easeOut",
        when: "beforeChildren",
        staggerChildren: 0.15,
      },
    },
  },
};

export const USER_PROFILE_AVATAR_ACTION = {
  NONE: "NONE",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
};

export const MANAGE_PRODUCT_STOCK_FIELD_TYPE = {
  NUMBER: "NUMBER",
  IMAGE: "IMAGE",
  CHECKBOX: "CHECKBOX",
};
